@import '../../../../scss/theme-bootstrap';

.esearch-product--typeahead {
  @include breakpoint($portrait-up) {
    float: $ldirection;
    padding: 0 0 $spacing;
    @if $search_modernized_design == true {
      width: 25%;
    } @else {
      width: 20%;
    }
  }
  &.product-grid-wrapper {
    border: none;
  }
  &--pc {
    @include text--tiny;
    padding: 0 $spacing--med;
  }
  &--mobile {
    padding: 0 0 $spacing;
    @if $search_modernized_design == true {
      display: flex;
      gap: 20px;
      @include breakpoint($portrait-up) {
        display: block;
      }
    }
  }
  &__image-link {
    display: block;
    text-align: center;
    margin-bottom: $spacing;
    @if $search_modernized_design == true {
      border-bottom: 0;
      height: 100px;
      width: 100px;
      @include breakpoint($portrait-up) {
        height: 223px;
        width: 223px;
      }
    } @else {
      border-bottom: $border--light;
    }
  }
  &__img {
    position: relative;
    max-height: 160px;
    @if $search_modernized_design == true {
      @include breakpoint($portrait-up) {
        max-height: 223px;
      }
    }
  }
}
